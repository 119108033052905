<template>
  <div>
    <div class="search">
      <el-row :gutter="20">
        <el-col :span="6">
          验证计划名称<el-input
            v-model="verifyPlanName"
            placeholder="请输入验证计划名称"
          ></el-input>
        </el-col>
        <el-col :span="6">
          项目名称<el-input
            v-model="projectName"
            placeholder="请输入项目名称"
          ></el-input>
        </el-col>
      </el-row>

      <div class="search-buttons">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="handler_search()"
          >搜索
        </el-button>
      </div>
    </div>
    <div class="result">
      <el-row
        v-for="(plan, index) in page.list"
        class="plan-container"
        :key="plan.id"
      >
        <el-row class="plan-title">
          <el-col :span="12" class="fl">{{ plan.name }}</el-col>
          <el-col :span="12">
            <span  class="fr">{{ formatPlanAddtime(index) }}</span>
          </el-col>
        </el-row>
        <el-row class="plan-body">
          <el-col
            :span="6"
            v-for="project in plan.projects"
            class="project-container"
            :key="project.id"
          >
            <el-row>
              <el-col :span="6" class="project-left"
                ><i class="el-icon-lx-home"></i
              ></el-col>
              <el-col :span="18" class="project-right">
                <div class="project-name">
                  {{ project.name }}
                </div>
                <div class="project-bottom">
                  <span
                    class="project-link fr"
                    @click="handler_completeproject(project.id)"
                    >完善材料</span
                  >
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-row>
      <el-pagination
        @current-change="devlogChange"
        :current-page="page.pageNum"
        :page-size="page.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
    </div>
  </div>
</template>
      
<script>
import PM from "../../api/base/api_pm";
import moment from "moment";

export default {
  name: "uncompleteList",

  data() {
    return {
      showLoading: false,
      page: { list: [], pageNum: 1, pageSize: 10, total: 0 },
      projectName: "",
      verifyPlanName: "",
    };
  },
  mounted() {
    this.init();
  },
  computed: {},
  // 方法定义
  methods: {
    init() {
      this.initPageData();
    },

    // 加载页面数据
    initPageData() {
      this.showLoading = true;

      PM.listUncompleteProject({
        pageNum: this.page?.pageNum,
        pageSize: this.page?.pageSize,
        verifyPlanName: this.verifyPlanName,
        projectName: this.projectName,
      }).then((res) => {
        this.showLoading = false;
        if (200 == res.code) {
          var data = res?.pageData;
          this.page.total = data?.total;
          this.page.list = data?.list;
        } else {
          this.$message.error(res?.msg);
          this.page.list = [];
        }
      });
    },
    formatPlanAddtime(index) {
      if (this.page && this.page.list) {
        var plan = this.page.list[index];
        if (plan && plan.addtime) {
          var addtime = plan.addtime;
          return moment(addtime).format("YYYY/MM/DD");
        }
      }
      return "";
    },
    devlogChange(pageNum) {
      this.page.pageNum = pageNum;
      this.initPageData();
    },
    handler_search() {
      this.initPageData();
    },
  },
};
</script>

<style scoped>
.fr {
  float: right;
}
.fl {
  float: left;
}
.search {
  margin-bottom: 10px;
}
.search-buttons {
  text-align: center;
  margin-top: 10px;
}
.el-row {
  width: 100%;
}
.plan-body {
  margin-bottom: 20px;
}

.plan-title {
  background-color: #02e3f4;
  padding: 5px 10px;
  margin-bottom: 15px;
  font-size: 14px;
}

.el-icon-lx-home:before {
  font-size: 60px;
}

.project-container {
  border: 1px solid #a7a7a7;
  box-shadow: 0 2px 5px #a7a7a7;
  padding: 10px;
  margin: 10px;
}

.project-title {
  padding: 0 10px;
}
.project-left {
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-right {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-content: center;
}
.project-name {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 14px;
  height: 60px;
  line-height: 20px;
  overflow: hidden;
  word-break: break-word;
  white-space: normal;
  margin-bottom: 10px;
}
.project-bottom {
  line-height: 20px;
}
.project-link {
  border: 1px solid #a7a7a7;
  padding: 0 10px;
  border-radius: 8px;
  font-size: 14px;
}
</style>
      